import React from 'react'
import { FaWhatsapp } from "react-icons/fa";

function Whatsapp() {
  return (
     <div className="btn-pink">
                 <a href="https://wa.me/916267144122"><FaWhatsapp/></a>
                
    </div>
  )
}

export default Whatsapp