export const navbar = [
 
  {
    id: 1,
    name: "HOME",
    path: "/",
  },
  {
    id: 2,
    name: "PROJECTS",
    path: "/#projects",
  },
  {
    id: 3,
    name: "TEAM",
    path: "/#team",
  },
  {
    id: 4,
    name: "ABOUT US",
    path: "/#about",
  },
  {
    id: 3,
    name: "SERVICES",
    path: "/#service",
  },

  // {
  //   id: 4,
  //   name: "TESTOMONALS",
  //   path: "#testimonials",
  // },

  {
    id: 5,
    name: "CONTACT",
    path: "/#contact",
  },
  // {
  //   id: 5,
  //   name: "CYBER SECUITY",
  //   path: "/cyber-security",
  // },
];
